import {
  Object3D,
  PerspectiveCamera,
  Scene,
  Vector2,
  Vector3,
  WebGLRenderer
} from "three";
import { WindowManager } from "../../utils/WindowManager";
import { room3d } from "./Room3d";
import { unitBrowser, UnitBrowser } from "./UnitBrowser";
import gsap from "gsap";
import * as THREE from "three";
import { mainMenu } from "../MainMenu";
import { state } from "../../Main";
import { OFF_BLACK_HEX, RENDERER } from "../../utils/Contants";

export const camera: PerspectiveCamera = new PerspectiveCamera(
  90,
  window.innerWidth / window.innerHeight,
  0.1,
  100
);
export const renderer: WebGLRenderer = new WebGLRenderer({
  antialias: false,
  alpha: true
});
export const scene: Scene = new Scene();
export const lookAtPosition: Vector3 = new Vector3(0, 0, 0);

class Gallery3d {
  public element: HTMLElement;
  public webglContainer: HTMLElement;
  public cssFOV: number = 0;
  private backgroundColor: string = OFF_BLACK_HEX;
  private raf: number;
  private onResize: any = this._onResize.bind(this);
  private slidUp: boolean;
  private updateBackground: boolean;

  constructor() {
    this.element = document.querySelector(".Gallery3d");
    this.webglContainer = this.element.querySelector(".webgl");
    //scene.fog = new THREE.Fog(this.backgroundColor, 5, 70);
    renderer.setPixelRatio(Math.min(devicePixelRatio, 1.5));
  }

  public initialize() {
    state.setValue(RENDERER, renderer);
    this.attachRenderer();
    camera.rotation.order = "YXZ";
    unitBrowser.initialize();
    room3d.initialize();
    WindowManager.signalResize.add(this.onResize);
  }

  public attachRenderer() {
    this.webglContainer.appendChild(renderer.domElement);
    this.render();
  }

  private _onResize() {
    camera.aspect = WindowManager.width / WindowManager.height;
    camera.updateProjectionMatrix();
    renderer.setSize(WindowManager.width, WindowManager.height);
    this.cssFOV =
      (0.5 / Math.tan((camera.fov * Math.PI) / 360)) * WindowManager.height;
    unitBrowser.resize();
    room3d.resize();
    if (this.slidUp) {
      this.slideUp(0);
    }
  }

  public show() {
    this.element.style.display = "block";
  }

  public start() {
    this.startAnimation();
    this.addEvents();
    this.onResize();
  }

  private addEvents() {
    this.removeEvents();
  }

  private removeEvents() {
    //
  }

  public startAnimation() {
    if (
      !this.raf &&
      !mainMenu.animatedIn &&
      this.webglContainer.children.length
    ) {
      this.animate();
    }
  }

  public stopAnimation() {
    if (this.raf) {
      cancelAnimationFrame(this.raf);
      this.raf = null;
    }
  }

  private animate = () => {
    this.raf = requestAnimationFrame(this.animate);
    this.render();
  };

  private render() {
    if (this.updateBackground) {
      this.element.style.backgroundColor = this.backgroundColor;
      const color = new THREE.Color(this.backgroundColor);
      unitBrowser.floorCeilingMaterial.color = color;
      this.updateBackground = false;
    }

    room3d.render();
    unitBrowser.render();
    renderer.render(scene, camera);
  }

  public setBackground(color: string, speed: number = 1, delay: number = 0) {
    gsap.to(this, speed, {
      backgroundColor: color,
      delay: delay,
      onUpdate: () => {
        this.updateBackground = true;
      }
    });
  }

  public slideUp(speed = 0.7) {
    this.slidUp = true;
    this.stopAnimation();
    room3d.pauseSpatialMusic();
    return new Promise(resolve => {
      gsap.to(this.element, speed, {
        y: -WindowManager.height / 2,
        ease: "Power2.easeInOut",
        onComplete: resolve
      });
    });
  }

  public slideDown(speed = 0.7) {
    this.slidUp = false;
    this.startAnimation();
    return new Promise(resolve => {
      gsap.to(this.element, speed, {
        y: 0,
        ease: "Power2.easeInOut",
        onComplete: () => {
          resolve();
          room3d.playSpatialMusic();
          this.startAnimation();
        }
      });
    });
  }

  public zoomOut(speed = 0.7) {
    this.stopAnimation();
    gsap.to(this.webglContainer, speed, {
      scale: 0.85,
      ease: "Power2.easeOut"
    });
    unitBrowser.zoomOut(speed);
  }

  public zoomNormal(speed = 0.7) {
    if (!mainMenu.animatedIn) {
      gsap.to(this.webglContainer, speed, {
        scale: 1,
        ease: "Power2.easeOut",
        onComplete: () => this.startAnimation()
      });
      unitBrowser.zoomNormal(speed);
    }
  }
}

export const gallery3d = new Gallery3d();
