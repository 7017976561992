import gsap from "gsap";
import Emitter from "@hellomonday/emitter";
import { checkExternalLink } from "../utils/Helpers";

export const ANIMATE_IN = "animate_in";
export const ANIMATE_OUT = "animate_out";

export class View extends Emitter {
  public element: HTMLElement;
  public animatedIn: boolean;

  constructor(element) {
    super(element);
    this.element = element;

    this.checkExternalLinks();
  }

  private checkExternalLinks() {
    for (const a of this.element.querySelectorAll("a[href]")) {
      if (checkExternalLink(a)) {
        a.setAttribute("target", "_blank");
      }
    }
  }

  public show() {
    this.element.style.display = "block";
  }

  public hide() {
    this.element.style.display = "none";
  }

  public animateIn() {
    if (this.animatedIn) {
      return;
    }
    this.animatedIn = true;
    this.show();
    this.emit(ANIMATE_IN, {});
    return new Promise(resolve => {
      gsap.set(this.element, { opacity: 0 });
      gsap.to(this.element, 0.5, {
        opacity: 1,
        onComplete: () => {
          this.animateInComplete();
          resolve();
        }
      });
    });
  }

  public animateInComplete() {}

  public animateOut() {
    if (!this.animatedIn) {
      return;
    }
    this.animatedIn = false;
    this.emit(ANIMATE_OUT, {});
    return new Promise(resolve => {
      gsap.to(this.element, 0.3, {
        opacity: 0,
        onComplete: () => {
          resolve();
          this.hide();
          this.animateOutComplete();
        }
      });
    });
  }

  public animateOutComplete() {}

  public toggleAnimate() {
    this.animatedIn ? this.animateOut() : this.animateIn();
  }

  public destroy() {}
}
